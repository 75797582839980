import React from "react";
import "../App.css";
import { Text } from "@aws-amplify/ui-react";

function Policy() {
  return (
    <div>
      <h3 style={{ color: "#fff", marginLeft: "20px", marginTop: "100px" }}>
        KanaSRS Privacy Policy
      </h3>
      <Text margin="30px" width="90%" fontSize="20px" border="none">
        KanaSRS does not store or share your data! The app was intentionally
        designed the way it was to limit permissions required, and steps
        required in using the app. No sign up or login, no extra social
        communications, nothing. Handwriting is verified through a machine
        learning algorithm, but is not saved or used for training. All main
        access to the app can be done offline.
      </Text>
    </div>
  );
}

export default Policy;
