import React, { useEffect, useRef } from "react";
import "../App.css";
import { Text, Divider } from "@aws-amplify/ui-react";

function Kousokukaki() {
  const headerRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      headerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Divider marginTop="10px" ref={headerRef} />
      <Text border="none">
        Currently under development. <br />
        Kousokukaki is a fast-paced Japanese writing competition versus other
        learners!
      </Text>
    </div>
  );
}

export default Kousokukaki;
