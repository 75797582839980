import React, { useEffect } from "react";
import useRef from "react-use-refs";
import "../App.css";
import Policy from "../pages/Policy";
import { Text, Divider, Button, Image } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import kanaLogo from "../images/kanasrs_logo.png";
import kanaBanner from "../images/kanasrs_banner.png";
import kana_screen1 from "../images/kana_screen1.webp";
import kana_screen2 from "../images/kana_screen2.webp";
import kana_screen3 from "../images/kana_screen3.webp";
import kana_screen4 from "../images/kana_screen4.webp";
import kana_screen5 from "../images/kana_screen5.webp";
import kana_screen6 from "../images/kana_screen6.webp";
import kana_screen7 from "../images/kana_screen7.webp";
import kana_screen8 from "../images/kana_screen8.webp";

function KanaSRS() {
  const [headerRef] = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      headerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const googlePlayLink = (
    <a href="https://play.google.com/store/apps/details?id=com.email.contact.kanasrs">
      Google Play Store
    </a>
  );

  const githubPage = (
    <a href="https://github.com/Lucas-Curran/KanaSRS">GitHub</a>
  );

  return (
    <div>
      <Divider marginTop="10px" ref={headerRef} />
      <div align="center">
        <Image
          alt="KanaSRS Banner"
          src={kanaBanner}
          width="80vw"
          className="rounded-background"
        />
      </div>
      <h2 style={{ color: "#fff", marginLeft: "20px" }}>Purpose</h2>
      <Text
        width="fit-content"
        style={{ whiteSpace: "pre-wrap" }}
        maxWidth={"100vw"}
        margin={"20px"}
        fontSize="20px"
      >
        KanaSRS is a Japanese learning app with the goal of teaching and
        quizzing the user over the two phonetic alphabets, Hiragana and
        Katakana.
        <br />
        <br />
        Currently, it is available on the {googlePlayLink}. You can also find
        the code on {githubPage}.
      </Text>

      <div align="center">
        <Image
          alt="KanaSRS Screenshot 1"
          src={kana_screen1}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
        <Image
          alt="KanaSRS Screenshot 2"
          src={kana_screen2}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
        <Image
          alt="KanaSRS Screenshot 3"
          src={kana_screen3}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
        <Image
          alt="KanaSRS Screenshot 4"
          src={kana_screen4}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
      </div>

      <div align="center">
        <Image
          alt="KanaSRS Screenshot 5"
          src={kana_screen5}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
        <Image
          alt="KanaSRS Screenshot 6"
          src={kana_screen6}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
        <Image
          alt="KanaSRS Screenshot 7"
          src={kana_screen7}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
        <Image
          alt="KanaSRS Screenshot 8"
          src={kana_screen8}
          width="20%"
          minWidth="200px"
          className="rounded-background"
        />
      </div>

      <div align="center">
        <Button
          width="50vw"
          align="center"
          onClick={() => navigate("/kanasrs/kanasrs-policy")}
        >
          Privacy Policy
        </Button>
      </div>
      <div align="center">
        <Image
          alt="KanaSRS Logo"
          src={kanaLogo}
          width="30vw"
          className="rounded-background"
        />
      </div>
    </div>
  );
}

export default KanaSRS;
