import { Button, ButtonGroup } from "@aws-amplify/ui-react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <TypeAnimation
        sequence={[
          "hello",
          1000,
          "welcome to my page",
          1000,
          "please take a look around",
        ]}
        className="text-animation"
      />
      <ButtonGroup size="large" padding={"30px"} direction="column">
        <Button isFullWidth={true} onClick={() => navigate("/about")}>
          About Me
        </Button>
        <Button isFullWidth={true} onClick={() => navigate("/projects/*")}>
          Projects
        </Button>
        <Button isFullWidth={true} onClick={() => navigate("/resume")}>
          Resume
        </Button>
        <Button isFullWidth={true} onClick={() => navigate("/wings")}>
          Wings
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default Home;
