import React from "react";
import { TypeAnimation } from "react-type-animation";
import "../App.css";
import { Text, Divider, Link } from "@aws-amplify/ui-react";

function About() {
  const projectsLink = (
    <Link href="/projects" color="#007EB9">
      projects
    </Link>
  );

  return (
    <div className="column">
      <TypeAnimation sequence={["about me"]} className="text-animation-small" />
      <Divider width="80vw" alignSelf="start" color="#fff" />
      <Text margin="30px" width="90%" fontSize="20px">
        Hello! Thanks for taking a look at my website! I'm Lucas Curran. I've
        built this website to showcase some of what I've done, while also
        serving as a fun project for myself.
      </Text>
      <Text margin="30px" width="90%" fontSize="20px">
        Currently, I work on android app development. I have multiple ongoing
        projects at anytime, which you can find more about on the {projectsLink}{" "}
        page.
      </Text>
    </div>
  );
}

export default About;
