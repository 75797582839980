import "../App.css";
import {
  Text,
  Image,
  Collection,
  Button,
  Divider,
  Rating,
  Icon,
} from "@aws-amplify/ui-react";

import dominoWings from "../images/dominos_wings.jpeg";
import pizzaHutWings from "../images/pizzahut_wings.webp";
import papaJohnsWings from "../images/papaJohns_wings.jpeg";
import React from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const items = [
  {
    restaurant: "Dominos",
    description: "Not amazing",
    wingImage: dominoWings,
    rating: 3.3,
  },
  {
    restaurant: "Pizza Hut",
    description: "Pretty good",
    wingImage: pizzaHutWings,
    rating: 4,
  },
  {
    restaurant: "Papa Johns",
    description: "Horrible",
    wingImage: papaJohnsWings,
    rating: 1.5,
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Wings() {
  return (
    <div>
      <Collection type="grid" items={items} templateColumns="1fr 1fr 1fr">
        {(items, index) => {
          return (
            <WingCard
              restaurant={items.restaurant}
              description={items.description}
              wingImage={items.wingImage}
              rating={items.rating}
            />
          );
        }}
      </Collection>
    </div>
  );
}

function WingCard(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {
    setOpen(false);
  };

  var ratingColor = "";
  if (props.rating <= 2) {
    ratingColor = "#FF0000";
  } else if (props.rating <= 3.5) {
    ratingColor = "#F58216";
  } else {
    ratingColor = "#86DC3D";
  }

  const IconStarBorder = () => {
    return (
      <Icon pathData="M22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.55 13.97L22 9.24ZM12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L12 6.1L13.71 10.14L18.09 10.52L14.77 13.4L15.77 17.68L12 15.4Z" />
    );
  };

  return (
    <div
      className="flex-container"
      margin="20px"
      style={{
        backgroundColor: "#424242A9",
      }}
    >
      <Text
        height="fit-content"
        fontSize="3vw"
        textAlign="center"
        margin="0px"
        border="none"
        className="restaurant-text"
      >
        {props.restaurant}
      </Text>
      <Image
        src={props.wingImage}
        height="100%"
        width="100%"
        borderRadius="9px"
        onClick={handleClickToOpen}
        className="wing-image"
      />
      <div>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={open}
          onClose={handleToClose}
          PaperProps={{
            style: {
              backgroundColor: "#424242",
            },
          }}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#222222" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleToClose}
                aria-label="close"
              >
                <CloseRoundedIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {props.restaurant}
              </Typography>
              <Rating
                size="large"
                value={props.rating}
                fillColor={ratingColor}
                emptyIcon={<IconStarBorder />}
                emptyColor="#AAAAAA"
              />
            </Toolbar>
          </AppBar>
          <Divider />
          <Text
            style={{
              color: ratingColor,
              margin: "20px",
              border: "1px solid white",
              width: "20vw",
              alignSelf: "center",
              textAlign: "center",
              padding: "5px",
              borderRadius: "0px",
              fontWeight: "bolder",
              fontSize: "10vw",
              backgroundColor: "#00000080",
            }}
          >
            {props.rating}
          </Text>
          <Text border="none" fontSize="20px">
            {props.description}
          </Text>
        </Dialog>
      </div>
    </div>
  );
}
