import React from "react";
import { TypeAnimation } from "react-type-animation";
import "../App.css";

function Resume() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <TypeAnimation sequence={["resume"]} className="text-animation" />
    </div>
  );
}

export default Resume;
