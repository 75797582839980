import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Info from "./pages/Info";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";
import Helmet from "react-helmet";
import Wings from "./pages/Wings";
import TagManager from "react-gtm-module";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./NavBar.js";

function App() {
  const tagManagerArgs = {
    gtmId: "G-JELZN5RF18",
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <div>
      <Helmet bodyAttributes={{ style: "background-color : #000000" }} />
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/info" exact element={<Info />} />
          <Route path="/projects/*" exact element={<Projects />} />
          <Route path="/resume" exact element={<Resume />} />
          <Route path="/wings" exact element={<Wings />} />
          <Route
            path="kanasrs-privacy-policy"
            render={() => {
              window.location.href = "public/kanasrs_privacy_policy.html";
            }}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
